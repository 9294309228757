import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";
const Footer = () => {

  return (
    <>
      <div className="footer">

        <div className="container-fluid fulwidth">
          <div className="row g-4">

            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6">
              <img src="https://webapi.entab.info/api/image/JRPSB/public/Images/footer-logo.png" class="img-fluid ftr-logo" />
              <div className='social_media'>
                <ul>
                  <li><i class="bi bi-instagram"></i></li>
                  <li><i class="bi bi-facebook"></i></li>
                  <li><i class="bi bi-youtube"></i></li>
                  <li><i class="bi bi-twitter-x"></i></li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-6 divider">
              <div className="quick-link">
                <h5>Quick links</h5>
                <ul>
                  {/* <li><Link to="/"> <i className="bi bi-caret-right-fill"></i> @School </Link></li>
                  <li><Link to="/"> <i className="bi bi-caret-right-fill"></i> About </Link></li> */}
                  <li><Link to="/Admission"> <i className="bi bi-caret-right-fill"></i> Admission </Link></li>
                  <li><Link to="/Tc"> <i className="bi bi-caret-right-fill"></i> Transfer Certificate </Link></li>
                  <li><Link to="/ContactUs"> <i className="bi bi-caret-right-fill"></i>  Contact Us</Link></li>
                  <li><Link to="/ErpSoftware"> <i className="bi bi-caret-right-fill"></i> Erp Software</Link></li>
                  <li><Link target="_blank" to="https://portalguidelines.campuscare.info/index.html#JRSBKL"> <i className="bi bi-caret-right-fill"></i> Parent (Help?)</Link></li>
                  {/* <li><Link to="/Pphelp"> <i className="bi bi-caret-right-fill"></i> Parent (Help?)</Link></li> */}
                  
                </ul>
              </div>
            </div>

            <div className="col-xl-5 col-lg-7 col-md-6 col-sm-12">
              <div className='contact'>
                <div className="cont-dtl">
                  <h5>Contact us</h5>
                  <p><Link to="https://maps.app.goo.gl/Bbcy7pDY4hT6SHxQ9" target="_blank"><i className="bi bi-geo-alt"></i> Balussery Panangad Rd, Balussery, Kerala 673612 </Link></p>
                  <p><Link to="tel:8089147954"><i className="bi bi-telephone-fill"></i> 8089147954</Link></p>
                </div>
                <div className="map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3910.395598786503!2d75.82686137653366!3d11.451345846379219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba6641fec4a2c7b%3A0x14a92987dd253176!2sJairani%20SABS%20Public%20School!5e0!3m2!1sen!2sin!4v1729251805453!5m2!1sen!2sin" width="100%" height="180" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-5 col-md-6 col-sm-12">
              <div className='d-flex footer-wrap'>
                <div className='d-flex mb-app'>
                  <div className='mobile-app'>
                    <p><b>Download Mobile App</b>
                      <br />A Common Platform For Educators, Parents & Teachers</p>
                    <div className='button-ftr'>
                      <a href="https://apps.apple.com/in/app/campuscare-10x/id1611283814" target="_blank"><img src="https://webapi.entab.info/api/image/JRPSB/public/Images/app-store.png" /></a>
                      <a href="https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN" target="_blank"><img src="https://webapi.entab.info/api/image/JRPSB/public/Images/google-app.png" /></a>
                    </div>
                    <p>Use School Code : JRSBKL</p>
                  </div>
                  <div>
                    <img src="https://webapi.entab.info/api/image/JRPSB/public/Images/mobile-app.png" className='img-fluid phone-img' />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="copyright">
        <p>©️Copyright Jairani SABS Public School Balussery, Kozhikode. All right reserved |  Created by Entab Infotech : <a href="https://entab.in/" target="_blank"> CampusCare®️</a></p>
      </div>
    </>
  )
}

export default Footer
